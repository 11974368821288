import firebaseApp from "../Firebase";
import { Team, TeamRole } from "../models/Team";
import { Repository } from "../repos/Repository";
import { Invite } from "../models/Invite";
import { NetworkHelper } from "../utils/NetworkHelper";
import { TeamSubscription } from "../models/TeamSubscription";
import { getFunctions, httpsCallable } from "firebase/functions";
import { SubscriptionOption } from "../models/SubscriptionOption";
import { User } from "../models/User";
import { CarouselData } from "../models/CarouselData";
import { RemoteConfig } from "../models/RemoteConfig";

export interface TeamData {
  team: Team;
  sub: TeamSubscription | null;
  carouselData: CarouselData[];
}

export interface TeamService {
  currentCarouselData: CarouselData[];
  carouselDataChanged: (data: CarouselData[]) => void;
  carouselDataRepo: Repository<CarouselData>;
  carouselDataPath(): string;
  subscriptionOptionRepo: Repository<SubscriptionOption>;
  subscriptionOptionPath(): string;
  teamRepo: Repository<Team>;
  teamPath(): string;
  teamSubscriptionRepo: Repository<TeamSubscription>;
  teamSubscriptionPath(): string;
  inviteRepo: Repository<Invite>;
  invitePath(teamId: string): string;
  remoteConfigRepo: Repository<RemoteConfig>;
  remoteConfigPath(): string;
  addSecrets(
    teamId: string,
    secrets: { [key: string]: string },
    configId: string
  ): Promise<void>;
  removeSecret(teamId: string, key: string): Promise<void>;
  // New custom secrets methods
  addCustomSecret(teamId: string, key: string, value: string): Promise<void>;
  deleteCustomSecret(teamId: string, key: string): Promise<void>;
  invite(
    teamId: string,
    email: string,
    role: TeamRole,
    baseUrl: string
  ): Promise<void>;
  acceptInvite(teamId: string): Promise<void>;
  createNewToken: (teamId: string) => Promise<string>;
  revokeToken(teamId: string, tokenId: string): Promise<void>;

  currentTeamDataChanged: (teamData: TeamData | undefined) => void;
  currentTeamData?: TeamData | undefined;
  fetchCurrentTeamData(teamId: string): Promise<TeamData>;

  getAllTeamsForUser(): Promise<Team[]>;
  purchase(
    priceId: string,
    teamId: string,
    baseUrl: string,
    userEmail: string
  ): Promise<string>;
  createTeam(teamName: string): Promise<Team>;
  deleteTeam(teamId: string): Promise<void>;
  updateSubscription(teamId: string, returnUrl: string): Promise<string>;
  updateTeamMembershipInfo(user: User): Promise<void>;
  updateStripeSubscription(teamId: string, priceId: string): Promise<void>;
}

export class FirestoreTeamService implements TeamService {
  constructor(
    public teamRepo: Repository<Team>,
    public teamSubscriptionRepo: Repository<TeamSubscription>,
    public inviteRepo: Repository<Invite>,
    public subscriptionOptionRepo: Repository<SubscriptionOption>,
    public carouselDataRepo: Repository<CarouselData>,
    public remoteConfigRepo: Repository<RemoteConfig>
  ) {}
  teamPath() {
    return "teams";
  }
  teamSubscriptionPath() {
    return "teamSubscriptions";
  }
  invitePath(teamId: string) {
    return `${this.teamPath()}/${teamId}/invites`;
  }
  subscriptionOptionPath() {
    return "subscriptionOptions";
  }
  carouselDataPath() {
    return "carouselData";
  }
  remoteConfigPath() {
    return "remoteConfig";
  }

  networkHelper = new NetworkHelper();

  async addSecrets(
    teamId: string,
    secrets: { [key: string]: string },
    configId: string
  ): Promise<void> {
    await this.networkHelper.sendRequest(
      "/team/" + teamId + "/secrets",
      "POST",
      { secrets, configId }
    );
  }

  async removeSecret(teamId: string, key: string): Promise<void> {
    await this.networkHelper.sendRequest(
      "/team/" + teamId + "/secrets/" + key,
      "DELETE"
    );
  }

  async invite(
    teamId: string,
    email: string,
    role: TeamRole,
    baseUrl: string
  ): Promise<void> {
    const functions = getFunctions(firebaseApp);
    const createInviteFn = httpsCallable(functions, "createInvite");
    await createInviteFn({ email, role, teamId, baseUrl });
  }

  async acceptInvite(teamId: string): Promise<void> {
    const functions = getFunctions(firebaseApp);
    const acceptInviteFn = httpsCallable(functions, "acceptInvite");
    await acceptInviteFn({ teamId });
  }

  async createNewToken(teamId: string): Promise<string> {
    const response = await this.networkHelper.sendRequest(
      "/team/" + teamId + "/token",
      "POST"
    );
    const json = await response.json();
    const jwtObject = json as { jwtToken: string };
    return jwtObject.jwtToken;
  }

  async revokeToken(teamId: string, tokenId: string): Promise<void> {
    await this.networkHelper.sendRequest(
      "/team/" + teamId + "/token/" + tokenId,
      "DELETE"
    );
  }

  currentTeamDataChanged: (teamData: TeamData | undefined) => void = () => {};
  currentTeamData: TeamData | undefined;
  currentCarouselData: CarouselData[] = [];
  carouselDataChanged: (data: CarouselData[]) => void = () => {};
  async fetchCurrentTeamData(teamId: string): Promise<TeamData> {
    const [team, sub, carouselData] = await Promise.all([
      this.teamRepo.get(this.teamPath(), teamId),
      this.teamSubscriptionRepo.get(this.teamSubscriptionPath(), teamId),
      this.carouselDataRepo.getList(this.carouselDataPath()),
    ]);

    if (!team) {
      throw new Error("Team data missing");
    }

    const data = { team, sub, carouselData };
    this.currentTeamData = data;
    this.currentTeamDataChanged(data);
    this.currentCarouselData = carouselData;
    this.carouselDataChanged(carouselData);
    return data;
  }

  async getAllTeamsForUser(): Promise<Team[]> {
    const functions = getFunctions(firebaseApp);
    const getTeamsForUserFn = httpsCallable(functions, "getTeamsForUser");
    const result = await getTeamsForUserFn();
    return result.data as Team[];
  }

  async createTeam(teamName: string): Promise<Team> {
    const functions = getFunctions(firebaseApp);
    const createTeamFn = httpsCallable(functions, "createTeam");
    const result = await createTeamFn({ teamName });
    return result.data as Team;
  }

  async deleteTeam(teamId: string): Promise<void> {
    const functions = getFunctions(firebaseApp);
    const deleteTeamFn = httpsCallable(functions, "deleteTeam");
    await deleteTeamFn({ teamId });
  }

  async purchase(
    priceId: string,
    teamId: string,
    baseUrl: string,
    userEmail: string
  ): Promise<string> {
    const createStripeCheckoutSessionFn = httpsCallable(
      getFunctions(firebaseApp),
      "createStripeCheckoutSession"
    );

    const sessionResponse = await createStripeCheckoutSessionFn({
      priceId,
      teamId,
      baseUrl,
      userEmail,
    });

    const { url } = sessionResponse.data as StripeSessionResponse;
    return url!;
  }

  async updateTeamMembershipInfo(user: User): Promise<void> {
    const userTeams = await this.getAllTeamsForUser();

    const teamUpdates = userTeams.map((team) => {
      const members = team.members;
      members[user.id].name = user.name;
      return this.teamRepo.update({ members }, this.teamPath(), team.id!);
    });
    await Promise.all(teamUpdates);
  }

  async updateSubscription(teamId: string, returnUrl: string): Promise<string> {
    const createUpdateSessionFn = httpsCallable(
      getFunctions(firebaseApp),
      "createUpdateSession"
    );
    const sessionResponse = await createUpdateSessionFn({
      teamId,
      returnUrl,
    });

    const { updateUrl } = sessionResponse.data as StripeUpdateSession;
    return updateUrl;
  }

  async updateStripeSubscription(
    teamId: string,
    priceId: string
  ): Promise<void> {
    const createUpdateSessionFn = httpsCallable(
      getFunctions(firebaseApp),
      "updateSubscription"
    );
    await createUpdateSessionFn({
      teamId,
      priceId,
    });
  }

  async addCustomSecret(
    teamId: string,
    key: string,
    value: string
  ): Promise<void> {
    await this.networkHelper.sendRequest(
      `/team/${teamId}/customSecrets`,
      "POST",
      { key, value }
    );
  }

  async deleteCustomSecret(teamId: string, key: string): Promise<void> {
    await this.networkHelper.sendRequest(
      `/team/${teamId}/customSecrets/${key}`,
      "DELETE"
    );
  }
}

interface StripeSessionResponse {
  sessionId: string;
  url: string | null;
}

interface StripeUpdateSession {
  updateUrl: string;
}
