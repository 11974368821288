import React, { useState, useRef, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { ChatMessage, Easybeam } from "easybeam-react";
import { CommonModal } from "../../../Common/CommonModal";
import Icon from "../../../Icon";
import { useTeams } from "../../../../contexts/TeamContext";
import { RemoteConfig } from "../../../../models/RemoteConfig";
import { CommonSpinner } from "../../../Common/CommonLoading";
import { PromptChat } from "./PromptChat";
import { PromptSuggestionBox } from "./PromptSuggestionBox";

interface PromptHelperModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSuggestPrompt: (prompt: string) => void;
  teamId: string;
}

export const PromptHelperModal: React.FC<PromptHelperModalProps> = ({
  isOpen,
  onClose,
  onSuggestPrompt,
  teamId,
}) => {
  const teamService = useTeams();
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [processedMessages, setProcessedMessages] = useState<ChatMessage[]>([]);
  const [suggestions, setSuggestions] = useState<
    { prompt: string; timestamp: string }[]
  >([]);
  const [currentSuggestionIndex, setCurrentSuggestionIndex] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const [error, setError] = useState<string>("");
  const [isConfigLoading, setIsConfigLoading] = useState(true);
  const [userId] = useState<string>(() => `user-${uuidv4().slice(0, 8)}`);
  const easybeamRef = useRef<Easybeam | null>(null);
  const [remoteConfig, setRemoteConfig] = useState<RemoteConfig>();

  useEffect(() => {
    const loadConfig = async () => {
      try {
        setIsConfigLoading(true);
        const config = await teamService.remoteConfigRepo.get(
          teamService.remoteConfigPath(),
          "version1"
        );
        setRemoteConfig(config ?? undefined);

        if (!config?.easybeamToken) {
          throw new Error("Easybeam token not found in configuration");
        }

        easybeamRef.current = new Easybeam({ token: config.easybeamToken });
      } catch (error) {
        console.error("Failed to load configuration:", error);
        setError("Failed to initialize prompt helper. Please try again later.");
      } finally {
        setIsConfigLoading(false);
      }
    };

    if (isOpen) {
      loadConfig();
    }
  }, [isOpen, teamService]);

  const processMessage = (content: string) => {
    const parts = content.split(/<promptSuggestion>|<\/promptSuggestion>/);
    let cleanedContent = "";
    let promptContent = null;

    parts.forEach((part, index) => {
      if (index % 2 === 0) {
        cleanedContent += part;
      } else {
        promptContent = part.trim();
      }
    });

    return {
      cleanedContent: cleanedContent.trim(),
      promptContent,
    };
  };

  useEffect(() => {
    const processedMsgs = messages
      .map((msg) => ({
        ...msg,
        content: processMessage(msg.content).cleanedContent,
      }))
      .filter((msg) => msg.content.length > 0);

    const newSuggestions = messages
      .map((msg) => {
        const { promptContent } = processMessage(msg.content);
        return promptContent
          ? { prompt: promptContent, timestamp: msg.createdAt }
          : null;
      })
      .filter(
        (suggestion): suggestion is NonNullable<typeof suggestion> =>
          suggestion !== null
      );

    setProcessedMessages(processedMsgs);
    setSuggestions(newSuggestions);

    if (newSuggestions.length > 0) {
      setCurrentSuggestionIndex(newSuggestions.length - 1);
    }
  }, [messages]);

  const handleSendMessage = async (message: string) => {
    if (!easybeamRef.current || !remoteConfig?.promptAgentId) return;

    const userMessage: ChatMessage = {
      id: uuidv4(),
      content: message,
      role: "USER",
      createdAt: new Date().toISOString(),
    };

    setMessages((prev) => [...prev, userMessage]);
    setIsRunning(true);

    const aiMessage: ChatMessage = {
      id: uuidv4(),
      role: "AI",
      content: "",
      createdAt: new Date().toISOString(),
    };
    setMessages((prev) => [...prev, aiMessage]);

    try {
      const formattedMessages = messages.map((msg) => ({
        content: msg.content,
        role: msg.role,
        createdAt: msg.createdAt,
        id: msg.id,
      }));

      formattedMessages.push({
        content: userMessage.content,
        role: userMessage.role,
        createdAt: userMessage.createdAt,
        id: userMessage.id,
      });

      await easybeamRef.current.streamAgent(
        remoteConfig.promptAgentId,
        userId,
        {},
        formattedMessages,
        (response) => {
          setMessages((prev) => {
            const newMessages = [...prev];
            const lastMessage = newMessages[newMessages.length - 1];
            if (lastMessage && lastMessage.role === "AI") {
              lastMessage.content = response.newMessage.content;
            }
            return newMessages;
          });
        },
        () => {
          setIsRunning(false);
        },
        (error) => {
          console.error("Error in stream:", error);
          setError(
            "Failed to get response from prompt helper. Please try again."
          );
          setMessages((prev) => {
            const newMessages = [...prev];
            const lastMessage = newMessages[newMessages.length - 1];
            if (lastMessage && lastMessage.role === "AI") {
              lastMessage.content =
                "Sorry, something went wrong. Please try again.";
            }
            return newMessages;
          });
          setIsRunning(false);
        }
      );
    } catch (error) {
      console.error("Failed to send message:", error);
      setError("Failed to send message. Please try again.");
      setIsRunning(false);
    }
  };

  if (isConfigLoading) {
    return (
      <CommonModal isOpen={isOpen} onDismiss={onClose}>
        <div className="flex items-center justify-center w-[1000px] h-[600px] bg-white rounded-lg shadow-lg">
          <CommonSpinner />
        </div>
      </CommonModal>
    );
  }

  if (error && !remoteConfig) {
    return (
      <CommonModal isOpen={isOpen} onDismiss={onClose}>
        <div className="flex flex-col items-center justify-center w-[1000px] h-[600px] bg-white rounded-lg shadow-lg p-6">
          <div className="text-red-600 mb-4">{error}</div>
          <button
            className="text-gray-400 hover:text-gray-500"
            onClick={onClose}
          >
            Close
          </button>
        </div>
      </CommonModal>
    );
  }

  return (
    <CommonModal isOpen={isOpen} onDismiss={onClose}>
      <div className="flex flex-col w-full max-w-[1000px] h-[600px] md:h-[600px] bg-white rounded-lg shadow-lg overflow-hidden">
        <div className="flex-none flex justify-between items-center px-6 py-4 border-b border-gray-200">
          <h2 className="text-xl font-gooper text-gray-900">
            Prompt Builder Assistant
          </h2>
          <button
            className="text-gray-400 hover:text-gray-500"
            onClick={onClose}
          >
            <Icon type="x" className="size-5" />
          </button>
        </div>

        <div className="flex flex-col md:flex-row flex-1 min-h-0">
          <div className="w-full md:w-1/2 h-1/2 md:h-full md:border-r border-gray-200">
            <PromptChat
              messages={messages}
              processedMessages={processedMessages}
              isRunning={isRunning}
              onSendMessage={handleSendMessage}
            />
          </div>

          <div className="w-full md:w-1/2 h-1/2 md:h-full bg-gray-50">
            <PromptSuggestionBox
              suggestions={suggestions}
              currentIndex={currentSuggestionIndex}
              onNavigate={setCurrentSuggestionIndex}
              onSelectPrompt={onSuggestPrompt}
              teamId={teamId}
            />
          </div>
        </div>
      </div>
    </CommonModal>
  );
};
