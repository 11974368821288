import React from "react";
import { Node, useReactFlow } from "reactflow";
import Icon from "../../../Icon";
import { createSelectorNode } from "./SelectorNode";
import { AddNodeHandler } from "../Handlers/AddNodeHandler";
import { Workflow } from "../../../../models/Workflow";
import { TeamVariable } from "../../../../models/Team";
import { ExternalPath } from "../../../../models/ExternalPath";

export const StartNodeType = "startNode";

export interface StartNodeData {
  teamId: string;
  type: typeof StartNodeType;
  hasProblem?: boolean;
  title: string;
  usedVariables?: { [id: string]: TeamVariable };
  demoVariables?: { [variable: string]: string };
  userSecrets?: { [key: string]: boolean };
  demoUserSecrets?: { [key: string]: string };

  id: string;
}

interface StartNodeProps {
  data: StartNodeData;
  id: string;
}

export const createStartNode = (
  teamId: string,
  workflow: Workflow
): Node<StartNodeData> => {
  return {
    id: StartNodeType,
    position: { x: 300, y: 300 },
    data: {
      type: StartNodeType,
      teamId,
      title: workflow.name,
      id: StartNodeType,
    },
    type: StartNodeType,
    draggable: false,
    selectable: true,
    deletable: false,
    selected: true,
  };
};

export const StartNode: React.FC<StartNodeProps> = ({ id, data }) => {
  const { getNode, setNodes, getEdges } = useReactFlow();

  const handleAdd = () => {
    const startNode = getNode(id);
    if (!startNode || sourceConnected()) return;

    const selectionNode = createSelectorNode(startNode, data.teamId);

    setNodes((nds) => nds.concat(selectionNode));
  };

  const sourceConnected = () => {
    const edges = getEdges();
    return edges.some((edge) => edge.source === id);
  };

  const selected = getNode(StartNodeType)?.selected;
  const nodeClasses = ` transition-all relative shadow group box-border border-2 ${
    data.hasProblem
      ? "border-red-500"
      : selected
      ? "border-blue-500"
      : "border-transparent hover:border-blue-500"
  } flex flex-col gap-1 rounded-lg p-3 bg-gray-0 w-56`;

  return (
    <div className={nodeClasses}>
      <div className="h-6 justify-start items-start inline-flex">
        <StartBadge />
      </div>
      <div
        id="startNodeTitle"
        className="text-2xl font-gooper text-gray-900 h-12 truncate"
      >
        {data.title}
      </div>
      <AddNodeHandler
        onClick={handleAdd}
        type="source"
        isConnected={sourceConnected()}
        id={`${id}_source`}
        alwaysShowAdd={true}
      />

      <div
        className={`${
          sourceConnected() ? "opacity-0" : "opacity-100"
        } flex flex-col gap-2 items-center transition-all absolute -left-20 -top-[160px] -right-20`}
      >
        <div
          id="startEmptyTitle"
          className="text-3xl font-gooper font-medium text-gray-900"
        >
          Let's get beamin'
        </div>
        <div className="text-lg text-gray-500">
          Attach your agent's first step to the start.
        </div>

        <a
          href={ExternalPath.agentDocs()}
          target="_blank"
          className="text-sm text-blue-500 hover:underline hover:text-blue-600"
        >
          Or read more about agents here.
        </a>
      </div>
    </div>
  );
};
export const StartBadge: React.FC = () => {
  return (
    <div className="px-2.5 py-0.5 bg-blue-50 rounded justify-center items-center gap-1 flex">
      <Icon type="home" className="size-3 text-blue-400" />
      <div className="text-center text-blue-500 text-xs font-medium leading-[18px]">
        Start
      </div>
    </div>
  );
};
