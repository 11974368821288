import { useReactFlow } from "reactflow";
import { PortalNodeData, SelectedInputs } from "../../Map/Nodes/PortalNode";
import { CommonInputSegment } from "../../../Common/CommonInput";
import { useEffect, useState, useRef } from "react";
import { VersionDetailCard } from "../../../Portals/Portal Detail/VersionDetailCard";
import { Team, TeamVariables } from "../../../../models/Team";
import { ChatConfigurationTemplate } from "../../../../models/ChatConfigurationTemplate";
import CommonSwitch from "../../../Common/CommonSwitch";
import { usePortalService } from "../../../../contexts/PortalContext";
import { CommonSpinner } from "../../../Common/CommonLoading";
import {
  extractVariables,
  extractVariableValues,
} from "../../../../utils/PromptUtil";
import { Portal } from "../../../../models/Portal";
import { PortalVersion } from "../../../../models/PortalVersion";
import { PortalSelectionModal } from "./PortalSelectionModal";
import { CommonUnderlineButton } from "../../../Common/CommonUnderlineButton";

export const SidebarPortalDetail: React.FC<{
  team: Team;
  setTeam: (team: Team) => void;
  data: PortalNodeData;
  templates: ChatConfigurationTemplate[];
}> = ({ team, data, templates, setTeam }) => {
  const { setNodes } = useReactFlow();
  const portalService = usePortalService();
  const [isPortalModalOpen, setIsPortalModalOpen] = useState(false);

  // Add internal state for version and a ref for tracking syncs
  const [internalVersion, setInternalVersion] = useState(data.currentVersion);
  const lastSyncedVersion = useRef<string>(JSON.stringify(data.currentVersion));

  // Handle syncing with external data
  useEffect(() => {
    if (!data.currentVersion) return;

    const currentVersionStr = JSON.stringify(data.currentVersion);
    if (currentVersionStr !== lastSyncedVersion.current) {
      setInternalVersion(data.currentVersion);
      lastSyncedVersion.current = currentVersionStr;
    }
  }, [data.currentVersion]);

  const updateNode = (newData: Partial<PortalNodeData>) => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === data.id) {
          return { ...node, data: { ...node.data, ...newData } };
        }
        return node;
      })
    );
  };

  useEffect(() => {
    const loadData = async () => {
      if (internalVersion != undefined) return;

      const newVersion = await portalService.createVersion(team.id!);
      setInternalVersion(newVersion);
      lastSyncedVersion.current = JSON.stringify(newVersion);
      updateNode({ currentVersion: newVersion });
    };
    loadData();
  }, []);

  const handlePortalSelect = async (portal: Portal, version: PortalVersion) => {
    setInternalVersion(version);
    lastSyncedVersion.current = JSON.stringify(version);

    updateNode({
      currentVersion: version,
      title: portal.name,
      selectedInputs: getSelectedInputs(version.prompt || ""),
      demoVariables: extractVariableValues(version.prompt || ""),
    });
  };

  const getSelectedInputs = (prompt: string): SelectedInputs => {
    const extracted = extractVariables(prompt);
    const existingInputs = data.selectedInputs ?? {};
    const selectedInputs: SelectedInputs = {};
    Object.values(extracted).forEach((value) => {
      if (existingInputs[value]) {
        selectedInputs[value] = existingInputs[value];
      } else {
        selectedInputs[value] = { type: "variable" };
      }
    });
    return selectedInputs;
  };

  const handledAddedVariable = async (newVariable: string) => {
    const newVariables = team?.variables ?? {};
    newVariables[newVariable] = { id: newVariable };
    setTeam({ ...team!, variables: newVariables });
  };

  const handleAddedVariables = async (variables: string[]) => {
    const existingVariables = team?.variables ?? {};
    const newVariables: TeamVariables = {};
    variables.forEach((variable) => {
      newVariables[variable] = {
        id: variable,
      };
    });
    setTeam({ ...team, variables: { ...existingVariables, ...newVariables } });
  };

  return (
    <div className="flex flex-col gap-4">
      <PortalSelectionModal
        isOpen={isPortalModalOpen}
        onDismiss={() => setIsPortalModalOpen(false)}
        teamId={team.id!}
        onSelect={handlePortalSelect}
      />
      {internalVersion && (
        <CommonInputSegment
          title="Prompt Step Title"
          onChange={(t) => updateNode({ title: t })}
          value={data.title}
          placeholder="Step Title"
          error={undefined}
          setError={undefined}
          id="beamTitle"
          className="font-sans text-gray-700"
          rightItem={
            <CommonUnderlineButton
              title="Click here to copy existing"
              onClick={() => setIsPortalModalOpen(true)}
            />
          }
        />
      )}

      {!internalVersion && <CommonSpinner />}

      {internalVersion && (
        <div className="flex flex-row justify-between items-center">
          <div className="flex flex-col">
            <div className="text-sm font-medium text-gray-700">
              Include Messages
            </div>
            <div className="text-xs text-gray-500">Send messages to the ai</div>
          </div>
          <CommonSwitch
            isOn={data?.includeMessages == true}
            onToggle={(n) => updateNode({ includeMessages: n })}
          />
        </div>
      )}
      {internalVersion && (
        <VersionDetailCard
          team={team}
          version={internalVersion}
          teamId={team.id!}
          updatePrompt={(prompt) => {
            const updatedVersion = { ...internalVersion, prompt };
            setInternalVersion(updatedVersion);
            lastSyncedVersion.current = JSON.stringify(updatedVersion);

            updateNode({
              currentVersion: updatedVersion,
              demoVariables: extractVariableValues(prompt ?? ""),
              selectedInputs: getSelectedInputs(prompt),
            });
          }}
          templates={templates}
          updatedVersion={(version) => {
            setInternalVersion(version);
            lastSyncedVersion.current = JSON.stringify(version);
            updateNode({ currentVersion: version });
          }}
          variables={
            Object.keys(team?.variables ?? {}).map(
              (key) => (team?.variables ?? {})[key].id
            ) ?? []
          }
          addedVariable={handledAddedVariable}
          hideCard={true}
          addedVariables={handleAddedVariables}
        />
      )}
    </div>
  );
};
